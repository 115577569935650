import React from "react"
import { Content, Layout, Heading1, Heading2, SEO } from "../components"

const Policy = () => {
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Content>
        <Heading1>Privacy Policy</Heading1>

        <Heading2>Personal data</Heading2>
        <p>
          <strong>Eye Dropper</strong> had never collected and will never collect any personal data,
          browsing history etc.
        </p>

        <p>
          In future <strong>Eye Dropper</strong> may collect your picked color history and extension
          settings (except any data which can help identify user). Such data may be used for
          decisions about implementing or removing features and getting some usefull/fun facts about
          picked colors. It will happen only with your permission.
        </p>

        <Heading2>Third party services</Heading2>

        <p>
          Eye Dropper uses Chrome(Chromium) or WebExtensions Storage Sync API and Web Storage
          API(localStorage) for storing user's settings and color history.
        </p>

        <p>
          Donations are processed by Open Collective and payment processors chosen by that
          organization(currently Stripe and PayPal).
        </p>
      </Content>
    </Layout>
  )
}

export default Policy
